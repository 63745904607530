header .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding: 30px 0px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 2em;
}

ul#nav {
    min-height: 48px;
    width: auto;
    background-color: rgba(0, 0, 0, 0.6);
    /* center align the menu */
    text-align: center;
}